import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")
  const _component_share_network = _resolveComponent("share-network")
  const _component_ion_fab_button = _resolveComponent("ion-fab-button")

  return (_openBlock(), _createBlock(_component_ion_fab_button, { color: "secondary" }, {
    default: _withCtx(() => [
      _createVNode(_component_share_network, {
        network: "facebook",
        url: _ctx.doc.url,
        title: _ctx.doc.title,
        description: _ctx.doc.shortAbstract2,
        quote: _ctx.doc.title,
        hashtags: "juranews",
        class: "text-decoration-none"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_icon, { name: "logo-facebook" })
        ]),
        _: 1
      }, 8, ["url", "title", "description", "quote"])
    ]),
    _: 1
  }))
}